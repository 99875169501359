var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "mt-4" },
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("div", { staticClass: "purchase_part" }, [
              _c(
                "div",
                { staticClass: "purchase_part-header" },
                [
                  _c("b-col", [
                    _c("p", { staticClass: "purchase_part-header_number" }, [
                      _vm._v(" " + _vm._s(_vm.production.ktru_item.name) + " "),
                    ]),
                  ]),
                  _c("b-col", { attrs: { cols: "auto" } }, [
                    _c(
                      "p",
                      { staticClass: "purchase_part-header_label fw-600" },
                      [
                        _vm._v(
                          "Годовой объем производства: " +
                            _vm._s(_vm.production.volume) +
                            " " +
                            _vm._s(_vm.production.ktru_item.okei_local_symbol)
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "purchase_part-body row" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "purchase_part-body_col",
                      attrs: { md: "6" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "h-100 d-flex flex-column justify-content-between",
                        },
                        [
                          _c("div", [
                            _c("div", { staticClass: "mb-2" }, [
                              _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                                _vm._v(" Группа КТРУ: "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "fs-12 m-0 grey-color fw-600 mb-4",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.production.ktru_item
                                            .ktru_group_name
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "mb-2" }, [
                              _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                                _vm._v(" Тип КТРУ: "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "fs-12 m-0 grey-color fw-600 mb-4",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.production.ktru_item
                                            .ktru_type_name
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "mb-2" }, [
                              _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                                _vm._v(" Описание: "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "fs-12 m-0 grey-color fw-600 mb-4",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.production.ktru_item.description
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "mb-2" }, [
                              _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                                _vm._v(" Единица измерения: "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "fs-12 m-0 grey-color fw-600 mb-4",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.production.ktru_item
                                            .okei_local_symbol
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "mb-2" }, [
                              _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                                _vm._v(" Код ОКПД2: "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "fs-12 m-0 grey-color fw-600 mb-4",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.production.ktru_item.okpd_code
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "mt-2" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "btn btn-card-action btn-card-action-green my-1 mx-1",
                                  attrs: {
                                    tag: "button",
                                    to: {
                                      name: "ProviderProductionsEdit",
                                      params: { id: _vm.production.id },
                                    },
                                  },
                                },
                                [_vm._v("Редактировать")]
                              ),
                              _c("delete-production-button", {
                                attrs: { production: _vm.production },
                                on: { click: _vm.deleteProduction },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "purchase_part-body_col",
                      attrs: { md: "6" },
                    },
                    [
                      _c("div", [
                        _c("div", { staticClass: "mb-4" }, [
                          _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                            _vm._v("Спецификации:"),
                          ]),
                          _c(
                            "ul",
                            { staticStyle: { "padding-inline-start": "20px" } },
                            _vm._l(
                              _vm.production.ktru_item.specs,
                              function (spec) {
                                return _c(
                                  "li",
                                  {
                                    key: spec.name + spec.value,
                                    staticClass: "fs-12 m-0 grey-color",
                                  },
                                  [
                                    _c("span", [_vm._v(_vm._s(spec.name))]),
                                    _c("span", { staticClass: "fw-900" }, [
                                      _vm._v(" - " + _vm._s(spec.value)),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }