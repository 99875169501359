<template>
    <b-modal title="Удаление производства" centered hide-footer no-close-on-backdrop no-close-on-esc @close="cancelModal" @cancel="cancelModal" :visible="show">
        <b-row class="py-2" no-gutters>
            <b-col
                >Вы действительно хотите удалить производство:<br /><br />
                {{ production.ktru_item.name }}?
            </b-col>
        </b-row>
        <b-row class="mt-5">
            <b-col class="text-right">
                <b-button type="reset" class="mr-2 text-uppercase" variant="custom-outline-secondary" @click="cancelModal">Отмена</b-button>
                <b-button type="submit" class="text-uppercase" variant="custom-danger" @click="okModal">Удалить</b-button>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
export default {
    name: 'DeleteProductionButtonModal',
    props: ['production', 'show'],
    data() {
        return {};
    },
    methods: {
        cancelModal() {
            this.$emit('cancel');
        },
        okModal() {
            this.$emit('ok');
        }
    }
};
</script>

<style></style>
