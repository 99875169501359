<template>
    <b-container class="mt-4">
        <b-row>
            <b-col>
                <div class="purchase_part">
                    <div class="purchase_part-header">
                        <b-col>
                            <p class="purchase_part-header_number">
                                {{ production.ktru_item.name }}
                            </p>
                        </b-col>
                        <b-col cols="auto">
                            <p class="purchase_part-header_label fw-600">Годовой объем производства: {{ production.volume }} {{ production.ktru_item.okei_local_symbol }}</p>
                        </b-col>
                    </div>
                    <div class="purchase_part-body row">
                        <b-col md="6" class="purchase_part-body_col">
                            <div class="h-100 d-flex flex-column justify-content-between">
                                <div>
                                    <div class="mb-2">
                                        <p class="fs-12 m-0 grey-color">
                                            Группа КТРУ:
                                            <span class="fs-12 m-0 grey-color fw-600 mb-4">
                                                {{ production.ktru_item.ktru_group_name }}
                                            </span>
                                        </p>
                                    </div>
                                    <div class="mb-2">
                                        <p class="fs-12 m-0 grey-color">
                                            Тип КТРУ:
                                            <span class="fs-12 m-0 grey-color fw-600 mb-4">
                                                {{ production.ktru_item.ktru_type_name }}
                                            </span>
                                        </p>
                                    </div>
                                    <div class="mb-2">
                                        <p class="fs-12 m-0 grey-color">
                                            Описание:
                                            <span class="fs-12 m-0 grey-color fw-600 mb-4">
                                                {{ production.ktru_item.description }}
                                            </span>
                                        </p>
                                    </div>
                                    <div class="mb-2">
                                        <p class="fs-12 m-0 grey-color">
                                            Единица измерения:
                                            <span class="fs-12 m-0 grey-color fw-600 mb-4">
                                                {{ production.ktru_item.okei_local_symbol }}
                                            </span>
                                        </p>
                                    </div>
                                    <div class="mb-2">
                                        <p class="fs-12 m-0 grey-color">
                                            Код ОКПД2:
                                            <span class="fs-12 m-0 grey-color fw-600 mb-4">
                                                {{ production.ktru_item.okpd_code }}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div class="mt-2">
                                    <router-link tag="button" class="btn btn-card-action btn-card-action-green my-1 mx-1" :to="{ name: 'ProviderProductionsEdit', params: { id: production.id } }">Редактировать</router-link>
                                    <delete-production-button :production="production" @click="deleteProduction"></delete-production-button>
                                </div>
                            </div>
                        </b-col>
                        <b-col md="6" class="purchase_part-body_col">
                            <div>
                                <div class="mb-4">
                                    <p class="fs-12 m-0 grey-color">Спецификации:</p>
                                    <ul style="padding-inline-start: 20px">
                                        <li class="fs-12 m-0 grey-color" v-for="spec of production.ktru_item.specs" :key="spec.name + spec.value">
                                            <span>{{ spec.name }}</span>
                                            <span class="fw-900"> - {{ spec.value }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </b-col>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import DeleteProductionButton from '@/components/cabinets/provider/productions/delete-production-button';

export default {
    name: 'ProductionCard',
    components: { DeleteProductionButton },
    props: ['production'],
    data() {
        return {
            tablet: false
        };
    },
    created() {
        if (window.innerWidth < 768) {
            this.tablet = true;
        }
    },
    methods: {
        deleteProduction() {
            this.$emit('deleteProduction', this.production);
        }
    }
};
</script>

<style scoped>
.purchase_part {
    background: #fff;
}
.purchase_part-header {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: #959ba4;
    padding: 9px 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.purchase_part-subheader {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: #e4e6e8;
    padding: 12px 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.purchase_part-body {
    padding: 28px 26px;
}

.purchase_part-header_number {
    font-size: 14px;
    color: #fff;
    margin: 0;
    font-weight: 600;
}

.purchase_part-header_label {
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
}

.purchase_part-body_col + .purchase_part-body_col > div::before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: #d9d9de;
    left: 0px;
}
@media screen and (max-width: 767px) {
    .purchase_part-body {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .purchase_part-body_col > div {
        position: relative;
    }

    .purchase_part-body_col > div::before {
        content: '';
        width: 100% !important;
        height: 1px !important;
        top: -12px;
    }

    .hidden-mobile {
        display: none;
    }

    .time_to_end {
        font-size: 12px !important;
        margin-bottom: 0 !important;
        /*margin-left: 5px !important;*/
    }

    /*.purchase_status {*/
    /*    margin-left: 5px !important;*/
    /*}*/
}
@media screen and (max-width: 768px) {
    .purchase_part-body_col::before {
        content: '';
        display: none;
    }
}
@media screen and (max-width: 420px) {
    .purchase_part-header {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -moz-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        min-height: 65px;
    }
}
</style>
