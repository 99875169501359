var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-button",
        {
          staticClass: "btn btn-card-action btn-card-action-danger my-1 mx-1",
          on: { click: _vm.deleteClick },
        },
        [_vm._v("Удалить")]
      ),
      _c("delete-production-button-modal", {
        attrs: { production: _vm.production, show: _vm.showModal },
        on: { cancel: _vm.cancelModal, ok: _vm.deleteProduction },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }