<template>
    <div>
        <b-button class="btn btn-card-action btn-card-action-danger my-1 mx-1" @click="deleteClick">Удалить</b-button>
        <delete-production-button-modal :production="production" @cancel="cancelModal" @ok="deleteProduction" :show="showModal"></delete-production-button-modal>
    </div>
</template>

<script>
import DeleteProductionButtonModal from '@/components/cabinets/provider/productions/delete-production-button-modal';

export default {
    name: 'DeleteProductionButton',
    props: ['production'],
    components: {
        DeleteProductionButtonModal
    },
    data() {
        return {
            showModal: false
        };
    },
    methods: {
        deleteClick() {
            this.showModal = true;
        },
        cancelModal() {
            this.showModal = false;
        },
        deleteProduction() {
            this.showModal = false;
            this.$emit('click');
        }
    }
};
</script>

<style></style>
