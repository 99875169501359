var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { md: "6" } }, [
            _c("h3", { staticClass: "grey-color main_text font-weight-bold" }, [
              _vm._v("Мое производство"),
            ]),
          ]),
          _c(
            "b-col",
            { staticClass: "text-right", attrs: { md: "6" } },
            [
              _c(
                "b-btn",
                {
                  attrs: {
                    to: { name: "ProviderProductionsCreate" },
                    disabled: !_vm.productionLocationFilled,
                    size: "sm",
                    variant: "custom-green",
                  },
                },
                [
                  _c("b-icon", {
                    staticClass: "my-auto mr-2",
                    attrs: { icon: "plus-circle", "aria-hidden": "true" },
                  }),
                  _vm._v(" Добавить производимый Товар / Работу / Услугу "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "d-flex align-items-center", attrs: { md: "4" } },
            [
              _c("span", { staticClass: "fs-14 grey-color" }, [
                _vm._v("Адрес местонахождения производства:"),
              ]),
            ]
          ),
          _c(
            "b-col",
            { staticClass: "fs-14 grey-color", attrs: { md: "8" } },
            [
              _c(
                "b-input-group",
                [
                  _c("VueSuggestions", {
                    staticClass: "form-control",
                    attrs: {
                      model: _vm.production_location,
                      placeholder: "Начните вводить",
                      options: _vm.suggestionOptions,
                    },
                    on: {
                      "update:model": function ($event) {
                        _vm.production_location = $event
                      },
                    },
                  }),
                  _c(
                    "b-input-group-append",
                    [
                      _c(
                        "b-btn",
                        {
                          staticClass: "btn btn-block btn-custom-green",
                          on: { click: _vm.updateProductionLocation },
                        },
                        [_vm._v(" Сохранить ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-container",
        { staticClass: "greyBg", attrs: { fluid: "", id: "content-block" } },
        [
          _vm._l(_vm.productions, function (production) {
            return _c(
              "b-container",
              { key: production.id },
              [
                _c("production-card", {
                  attrs: { production: production },
                  on: { deleteProduction: _vm.deleteProduction },
                }),
              ],
              1
            )
          }),
          !_vm.loading && !_vm.productions.length
            ? _c("b-container", [_c("empty-request")], 1)
            : _vm._e(),
          _vm.productions.length
            ? _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "100%",
                              "margin-top": "12px",
                            },
                          },
                          [
                            _c("b-pagination", {
                              attrs: {
                                align: "center",
                                "total-rows": _vm.meta.total,
                                "per-page": _vm.meta.per_page,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.getProductions()
                                },
                              },
                              model: {
                                value: _vm.meta.current_page,
                                callback: function ($$v) {
                                  _vm.$set(_vm.meta, "current_page", $$v)
                                },
                                expression: "meta.current_page",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }