var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        title: "Удаление производства",
        centered: "",
        "hide-footer": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        visible: _vm.show,
      },
      on: { close: _vm.cancelModal, cancel: _vm.cancelModal },
    },
    [
      _c(
        "b-row",
        { staticClass: "py-2", attrs: { "no-gutters": "" } },
        [
          _c("b-col", [
            _vm._v("Вы действительно хотите удалить производство:"),
            _c("br"),
            _c("br"),
            _vm._v(" " + _vm._s(_vm.production.ktru_item.name) + "? "),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-5" },
        [
          _c(
            "b-col",
            { staticClass: "text-right" },
            [
              _c(
                "b-button",
                {
                  staticClass: "mr-2 text-uppercase",
                  attrs: { type: "reset", variant: "custom-outline-secondary" },
                  on: { click: _vm.cancelModal },
                },
                [_vm._v("Отмена")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "text-uppercase",
                  attrs: { type: "submit", variant: "custom-danger" },
                  on: { click: _vm.okModal },
                },
                [_vm._v("Удалить")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }